<template>
	<el-drawer custom-class="drawerIconNone" v-model="drawerVisible" :close-on-click-modal="false" title="公证申请"
		direction="rtl" size="500px">
		<div class="detailsbox">
			<div class="ovbox">
				<!-- {{ state.baseInfo.contractStatus }} -->
				<div class="labelbox dp-f">
					<div class="label">合同标题:</div>{{ state.baseInfo.contractName }}
				</div>
				<div class="labelbox dp-f">
					<div class="label">申请类型:</div>
					<div>
						<el-select v-model="state.evidType" placeholder="Select" style="width: 240px" @change="getQrCode()">
							<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value"
							:disabled="item.disabled"
							/>
						</el-select>
					</div>	
				</div>
				<div class="labelbox dp-f">
					<div class="label">付款金额:</div>{{ state.payAmount }} 元
				</div>
				<div class="labelbox dp-f">
					<div class="label">微信支付:</div>
					<div>
						<el-image class="w-136 h-136" :src="state.codeimg"> 
							<template #error>
								<div class="image-slot">
									<el-icon><icon-picture /></el-icon>
								</div>
							</template>
						</el-image>
					</div>
				</div>
				<div class="labelbox dp-f">
					<div class="label">支付结果:</div> 
					<div v-if="state.paySucess">支付成功,请等待5分钟后下载存证报告</div>
					<div v-else>支付中</div>
				</div>
				<!-- <items :items="state.baseInfo.contractSignRecords"></items> -->
			</div>
			<!-- return code == 0?'待我处理':code == 1?'签署中':code == 2?'已完成':code == 3?'已过期':code == 4?'草稿箱':code == 5?'抄送':'未知' -->
			<div class="fotbox dp-f jc-fe ai-c">
				<!-- <oabutton v-if="state.withdrawIs" class="searcML" width='100' height='40' title="撤回" CSStype=3
					@buttonclick="clickUserCheck(()=>{withdraw(state.baseInfo.id)})" /> -->
				<oabutton class="searcML" width='100' height='40' title="关闭" CSStype=3 @buttonclick="drawerClose" />
				<!-- 实名后才有签署按钮 -->
				<!-- <oabutton v-if="state.baseInfo.contractStatus == 0" class="searcML mr-12" width='100' height='40' title="签署"
					CSStype=2 @buttonclick="clickUserCheck(()=>{signClick(1)})" v-model:loading="dialogloading" />
				<oabutton v-else class="searcML mr-12" width='100' height='40' title="查看合同" CSStype=2
					@buttonclick="signClick()" /> -->
			</div>
		</div>
	</el-drawer>
</template>
<script setup>
import { reactive, ref, defineEmits, computed } from 'vue'
import { getcontractStatus, getSignOrder } from "@/utils/server/getcode.js"
import { router_push_name } from "@/utils/server/router.js"
import { handleCofirm, handleMessage } from "@/utils/server/confirm";
import {clickUserCheck} from "@/utils/base/realname.js"
import { httpToken } from "@/utils/request";
import qs from "qs";
import items from "./items.vue"
const dialogloading = ref(false);// 弹框按钮loading 显示
const emit = defineEmits(['withdrawEmit'])
const drawerVisible = ref(false)//抽屉显示
const state = reactive({
	evidType: 1,
	selectionCount: 0,//选中数量
	baseInfo: {},
	codeimg: '',
	codenumber: '',
	timmer: null,
	paySucess: false,
})
const userInfo = computed(() => {
	return JSON.parse(window.sessionStorage.user).user_info
})
const options = [
  {
    value: 1,
    label: '保全函',
  },
  {
    value: 2,
    label: '公证书',
    disabled: true,
  }
]
const drawerClose =() => {
	if (state.timmer) {
		window.clearInterval(state.timmer);
	}
	drawerVisible.value = false
}
// 撤回点击
const withdraw = (id) => {
	httpToken({
		method: "post",
		url: '/initiate-contract/contract/recallContract',
		data: qs.stringify({
			id: id
		}),
	}).then((res) => {
		emit('withdrawEmit')
		drawerVisible.value = false
	})
}
const getQrCode = () => {
	state.codeimg = ""
	state.paySucess = false
	if (state.timmer) {
		window.clearInterval(state.timmer);
	}
	let packageType 
	if (state.evidType == 1) {
		packageType = 3
	} else {
		packageType = 4
	}

	httpToken({
		method: "post",
		url: 'admin/packageservice/getPackageDTO',
		data: qs.stringify({
			type: packageType 
		}),
	}).then((res) => {
		if (res.data.records.length > 0) {
			state.payAmount = res.data.records[0].packageMoney
			let json = {
				rechargeType: 0,
				rechargeMethod: 1,
				menuType: res.data.records[0].menuType,
				contractId: state.baseInfo.id
			}			
			
			httpToken({
				method: "post",
				url: 'admin/account/getQRCode',
				data: json,
			}).then((res) => {
				state.codeimg = res.data.qrbaseStr
				state.codenumber = res.data.number
				state.timmer = setInterval(() => {
					httpToken({
					method: "post",
					url: '/admin/account/queryOrderStatus',
					data: qs.stringify({
						number: state.codenumber//订单号
					})
					}).then((res) => {
					if (res.data == 2) {
						window.clearInterval(state.timmer); //清除定时器
						state.timmer = null;
						handleMessage('支付成功,请等待5分钟后下载存证报告', 'success')
						state.paySucess = true
						// router_push_name('account_center')
					}
					})
				}, 1000)	
			})
		}
	})
}
const getDetails = ((data) => {
	state.baseInfo = data
	drawerVisible.value = true
	getQrCode()
})
// isAuthentication 企业实名认证 0是未认证 1是已认证
// isCertification 个人实名认证状态 0是未认证 1是已认证
// 0默认 1签署
const signClick = ((type=0) => {
	// if (type == 1) {
	// 	if (userInfo.isCertification != 1) {
	// 		handleMessage('请先完成个人实名认证')
	// 		return
	// 	}
	// 	if (userInfo.isAuthentication != 1) {
	// 		handleMessage('请先完成企业实名')
	// 		return
	// 	}
	// }
	router_push_name('sign_details', { id: state.baseInfo.id,type:type }, undefined, true)
})
defineExpose({
	getDetails
});
</script>
<style lang="scss" scoped >
@import "@/styles/general/layout/labelbox.scss";
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 30px;
}

.detailsbox {
	width: 100%;
	height: 100%;
	position: relative;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
	padding: 0 34px;
	padding-top: 34px;

	.ovbox {
		height: calc(100% - 60px);
		overflow: auto;
	}

	.labelbox {
		.status {
			color: #0083FF;
		}

		.status3,
		.status4 {
			color: #999999;
		}
	}

	.fotbox {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 60px;
		background: #FFFFFF;
		box-shadow: 0px -1px 8px 0px rgba(219, 219, 219, 0.5);
	}

	// 隐藏滚动条
	::-webkit-scrollbar {
		width: 0 !important
	}
}
</style>